import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store/index'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '夷正科技'
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
    children: [{
      path: '/',
      name: 'Index',
      meta: {
        title: '夷正科技'
      },
      component: () => import(/* webpackChunkName: "Index" */ '../views/portal/index.vue')
    },
    {
      path: '/about',
      name: 'About',
      meta: {
        title: '夷正科技-关于夷正'
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/portal/about.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      meta: {
        title: '夷正科技-联系我们'
      },
      component: () => import(/* webpackChunkName: "contact" */ '../views/portal/contact.vue')
    }]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '夷正科技-登录'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      title: '夷正科技-管理后台',
      name: '首页'
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/admin.vue'),
    children: [{
      path: 'example',
      name: 'Example',
      meta: {
        title: '夷正科技-组件',
        name: '组件'
      },
      component: () => import(/* webpackChunkName: "example" */ '../views/admin/elment/example.vue')
    },
    {
      path: 'setting',
      name: 'Setting',
      redirect: 'personal',
      meta: {
        title: '夷正科技-设置',
        name: '设置'
      },
      children: [{
        path: 'personal',
        name: 'Personal',
        meta: {
          title: '夷正科技-个人中心',
          name: '个人中心'
        },
        component: () => import(/* webpackChunkName: "personal" */ '../views/admin/setting/personal.vue')
      },
      {
        path: 'security',
        name: 'Security',
        meta: {
          title: '夷正科技-安全设置',
          name: '安全设置'
        },
        component: () => import(/* webpackChunkName: "security" */ '../views/admin/setting/security.vue')
      }]
    }]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to: any, from: any, next: any) => {
  // 未登陆
  if (!window.localStorage.getItem('token') && to.path.indexOf('/admin') === 0) {
    return next({ path: '/login' })
  }
  // 已登陆
  if (window.localStorage.getItem('token') && to.path === '/login') return next({ path: '/admin' })

  /** 新进入页面刷新 */
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  // window.pageYOffset = 0
  next()
})

router.afterEach((to: any, from: any, next: any) => {
  // 添加网站标题
  if (to.meta.title && typeof (to.meta.title) === 'string') {
    document.title = to.meta.title
  }
  // 顶部面包屑
  const routerList = to.matched
  store.commit('SET_CRUMBLIST', routerList)
  // 当前active导航菜单
  store.commit('SET_CURRENT_MENU', to.name)
})

export default router
