<template>
  <router-view/>
</template>

<style lang="scss">
body,html {
  padding: 0;
  margin: 0;
}
</style>
