import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElIcons from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/index.css'

const app = createApp(App)
for (const name in ElIcons) {
  app.component(name, (ElIcons as never)[name])
}
app.use(store).use(router).use(ElementPlus, { locale: zhCn }).mount('#app')
