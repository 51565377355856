import { createStore } from 'vuex'

export default createStore({
  state: {
    /* 所有路由 */
    permissionList: null,
    /* 当前active导航菜单 */
    currentMenu: '',
    /* 导航菜单是否折叠 */
    isCollapse: false,
    /* 面包屑导航列表 */
    crumbList: []
  },
  getters: {
    getCurrentMenu: (state: any) => state.currentMenu,
    getIsCollapse: (state: any) => state.isCollapse,
    getCrumbList: (state: any) => state.crumbList
  },
  mutations: {
    /* 所有路由 */
    SET_PERMISSION (state: any, routes: Array<any>) {
      state.permissionList = routes
    },
    /* 当前active导航菜单 */
    SET_CURRENT_MENU (state: any, currentMenu: string) {
      state.currentMenu = currentMenu
    },
    /* 导航菜单是否折叠 */
    switchCollapse (state: any, isCollapse: boolean) {
      state.isCollapse = isCollapse
    },
    /* 面包屑导航列表 */
    SET_CRUMBLIST (state: any, list: Array<any>) {
      state.crumbList = list
    }
  },
  actions: {
  },
  modules: {
  }
})
